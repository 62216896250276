import * as React from 'react'
import { css } from 'linaria'

import { Layout } from '../components/layout'

const styleInputGroup = css`
  margin-bottom: 1rem;

  label, input {
  }

  label {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  input {
    display: block;
    font-size: 1.2rem;
    padding: 0.5em;
    border: 1px solid gray;
    border-radius: 2px;
  }
`

const styleButton = css`
  color: #fff;
  background: tomato;
  padding: 0.5em 1em;
  border-radius: 2px;
  border: 0;

  &:hover {
    background: orange;
  }
`

const Subscribe: React.FC = () => {
  return (
    <Layout>
      <div id="mc_embed_signup">
        <form action="https://penandpillow.us20.list-manage.com/subscribe/post?u=1a1a79833426933cbde1f409a&amp;id=13c5ffe07b" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
          <div id="mc_embed_signup_scroll">
            <h1>Subscribe</h1>
            <p>Hi there,</p>
            <p>{'I\'m glad you find this blog interesting enough to hit the \'subscribe\' link! I\'ll email you whenever I publish a new post (probably every 2 or 3 weeks.)'}</p>
            <p>{'Gatsby is my current obsession, but I may also write about other web-dev-related topics.'}</p>
            <div className={styleInputGroup}>
              <label htmlFor="mce-EMAIL">Email Address </label>
              <input type="email" name="EMAIL" id="mce-EMAIL" />
            </div>
            <div id="mce-responses">
              <div id="mce-error-response" style={{ display: 'none' }}></div>
              <div id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div style={{
              position: 'absolute',
              left: '-5000px',
            }} aria-hidden="true">
              <input type="text" name="b_1a1a79833426933cbde1f409a_13c5ffe07b" tabIndex={-1} defaultValue="" />
            </div>
            <div>
              <button className={styleButton} type="submit" value="Subscribe" name="subscribe">Subscribe</button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Subscribe